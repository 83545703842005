/**
 * Datart
 *
 * Copyright 2021
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { DownloadFileType } from 'app/constants';
import { migrateWidgets } from 'app/migration/BoardConfig/migrateWidgets';
import { FilterSearchParamsWithMatch } from 'app/pages/MainPage/pages/VizPage/slice/types';
import { mainActions } from 'app/pages/MainPage/slice';
import { ChartDataRequest } from 'app/types/ChartDataRequest';
import {makeDownloadDataTask, syncDownloadData} from 'app/utils/fetch';
import { RootState } from 'types';
import { boardActions } from '.';
import { getBoardChartRequests } from '../../../utils';
import {
  getChartDataView,
  getDashBoardByResBoard,
  getDataChartsByServer,
  getInitBoardInfo,
  getScheduleBoardInfo,
} from '../../../utils/board';
import { getWidgetInfoMapByServer, getWidgetMap } from '../../../utils/widget';
import { PageInfo } from './../../../../MainPage/pages/ViewPage/slice/types';
import { getChartWidgetDataAsync, getWidgetData } from './thunk';
import { BoardState, DataChart, ServerDashboard, VizRenderMode } from './types';
import {stat} from "fs";

export const handleServerBoardAction =
  (params: {
    data: ServerDashboard;
    renderMode: VizRenderMode;
    filterSearchMap?: FilterSearchParamsWithMatch;
  }) =>
  async (dispatch, getState) => {
    const { data, renderMode, filterSearchMap } = params;
    const dashboard = getDashBoardByResBoard(data);
    const { datacharts, views: serverViews, widgets: serverWidgets } = data;

    const dataCharts: DataChart[] = getDataChartsByServer(datacharts);
    const migratedWidgets = migrateWidgets(serverWidgets);
    const { widgetMap, wrappedDataCharts, controllerWidgets } = getWidgetMap(
      migratedWidgets,
      dataCharts,
      filterSearchMap,
    );
    const widgetIds = Object.values(widgetMap).map(w => w.id);
    let boardInfo = getInitBoardInfo({
      id: dashboard.id,
      widgetIds,
      controllerWidgets,
    });
    if (renderMode === 'schedule') {
      boardInfo = getScheduleBoardInfo(boardInfo, widgetMap);
    }
    const widgetInfoMap = getWidgetInfoMapByServer(widgetMap);

    const allDataCharts: DataChart[] = dataCharts.concat(wrappedDataCharts);

    const viewViews = getChartDataView(serverViews, allDataCharts);

    await dispatch(
      boardActions.setBoardState({
        board: dashboard,
        boardInfo: boardInfo,
      }),
    );
    dispatch(boardActions.setViewMap(viewViews));
    dispatch(boardActions.setDataChartToMap(allDataCharts));
    dispatch(
      boardActions.setWidgetMapState({
        boardId: dashboard.id,
        widgetMap: widgetMap,
        widgetInfoMap: widgetInfoMap,
      }),
    );
  };

export const boardDownLoadAction =
  (params: { boardId: string; downloadType: DownloadFileType; withStyle: boolean }) =>
  async (dispatch, getState) => {
    const state = getState() as RootState;
    const { boardId, downloadType, withStyle } = params;
    const vizs = state.viz?.vizs;
    const folderId = vizs?.filter(v => v.relId === boardId)[0].id;
    const boardInfoRecord = state.board?.boardInfoRecord;
    let imageWidth = 0;

    if (boardInfoRecord) {
      const { boardWidthHeight } = Object.values(boardInfoRecord)[0];
      imageWidth = boardWidthHeight[0];
    }

    const { requestParams, fileName } = await dispatch(
      getBoardDownloadParams({ boardId }),
    );

    dispatch(
      makeDownloadDataTask({
        downloadParams:
          downloadType === DownloadFileType.Excel
            ? requestParams
            : [{ analytics: false, vizType: 'dashboard', vizId: folderId }],
        fileName,
        downloadType,
        imageWidth,
        resolve: () => {
          dispatch(mainActions.setDownloadPolling(true));
        },
        downloadWithStyle: withStyle,
      }),
    );
  };

export const boardSyncDownloadAction =
  (params: { boardId: string; downloadType: DownloadFileType; withStyle: boolean; downloadModuleRelatedWidgetIds?: string[] }) =>
    async (dispatch, getState) => {
      const state = getState() as RootState;
      const { boardId, downloadType, withStyle, downloadModuleRelatedWidgetIds } = params;
      const vizs = state.viz?.vizs;
      const folderId = vizs?.filter(v => v.relId === boardId)[0].id;
      const boardInfoRecord = state.board?.boardInfoRecord;
      const executeTokenMap = state.share?.executeTokenMap;
      let imageWidth = 0;

      if (boardInfoRecord) {
        const { boardWidthHeight } = Object.values(boardInfoRecord)[0];
        imageWidth = boardWidthHeight[0];
      }
      const href = window.location.href;
      const { requestParams, fileName } = await dispatch(
        getBoardDownloadParams({ boardId, downloadModuleRelatedWidgetIds }),
      );
      let share = false;
      if (href.indexOf('shareDashboard') > -1) {
        share = true;
      }
      dispatch(
        syncDownloadData({
          downloadParams:
            downloadType === DownloadFileType.Excel
              ? requestParams
              : [{ analytics: false, vizType: 'dashboard', vizId: folderId }],
          fileName,
          downloadType,
          imageWidth,
          resolve: () => {
            dispatch(mainActions.setDownloadPolling(true));
          },
          downloadWithStyle: withStyle,
          shareBoard: share,
          executeToken: executeTokenMap
        }),
      );
    };

export const getBoardDownloadParams =
  (params: { boardId: string, downloadModuleRelatedWidgetIds?: string[] }) => (dispatch, getState) => {
    const { boardId, downloadModuleRelatedWidgetIds } = params;
    const boardState = getState() as { board: BoardState };
    const widgetMapMap = boardState.board.widgetRecord;
    const widgetMap = widgetMapMap[boardId];
    const viewMap = boardState.board.viewMap;
    const dataChartMap = boardState.board.dataChartMap;

    const fileName = boardState.board?.boardRecord?.[boardId].name || 'board';
    let requestParams = getBoardChartRequests({
      widgetMap,
      viewMap,
      dataChartMap,
      downloadModuleRelatedWidgetIds,
    }) as ChartDataRequest[];

    requestParams.map(item => item.dashboardId = boardId)

    return { requestParams, fileName };
  };

export const widgetsQueryAction =
  ({ boardId, renderMode }) =>
  (dispatch, getState) => {
    const pageInfo: Partial<PageInfo> = {
      pageNo: 1,
    };
    const boardState = getState() as { board: BoardState };
    const boardMapWidgetMap = boardState.board.widgetRecord;
    const widgetMap = boardMapWidgetMap[boardId];
    // console.log("widgetMap", boardState.board.tabPanel[boardId]);
    //
    // let loadkeys = boardState.board.tabPanel[boardId]?.activeKeys || [];
    // dispatch(boardActions.setTabPanelLoadKey({boardId, keys: loadkeys}));
    //
    // if(Object.values(widgetMap).every(it => it.config.type !== 'reset')) {
    //   loadkeys = [];
    // }

    Object.values(widgetMap)
      .filter(it => it.config.type === 'chart')
      // .filter(it => !loadkeys.includes(it.id))
      .forEach(it => {
        dispatch(
          getChartWidgetDataAsync({
            boardId,
            widgetId: it.id,
            renderMode,
            option: { pageInfo },
          }),
        );
      });
  };

// export const widgetsQueryItemAction =
//   ({ boardId, renderMode, id }) =>
//   (dispatch, getState) => {
//     const pageInfo: Partial<PageInfo> = {
//       pageNo: 1,
//     };
//
//     console.log({
//       boardId,
//       widgetId: id,
//       renderMode,
//       option: { pageInfo },
//     });
//
//
//     dispatch(
//       getChartWidgetDataAsync({
//         boardId,
//         widgetId: id,
//         renderMode,
//         option: { pageInfo },
//       }),
//     );
//   };

export const resetControllerAction =
  ({ boardId, renderMode }) =>
  async (dispatch, getState) => {
    const boardState = getState() as { board: BoardState };
    const boardInfo = boardState.board.boardInfoRecord[boardId];
    if (!boardInfo) return;
    dispatch(boardActions.resetControlWidgets({ boardId }));
    const boardMapWidgetMap = boardState.board.widgetRecord;
    const widgetMap = boardMapWidgetMap[boardId];

    const pageInfo: Partial<PageInfo> = {
      pageNo: 1,
    };


    // if(Object.values(widgetMap).some(it => it.config.type === 'reset')) {
    //   dispatch(boardActions.setTabPanelLoadKey({boardId, keys: []}));
    // }

    Object.values(widgetMap).forEach(widget => {
      dispatch(
        getWidgetData({
          boardId,
          widget: widget,
          renderMode,
          option: { pageInfo },
        }),
      );
    });
  };
