export const ZH_CN = {
  小计: '小计',
  总计: '总计',
  总和: '总和',
  项: '项',
  已选择: '已选择',
  序号: '序号',
  度量: '所选项',
  数值: '数值',
  共计: '共计',
  条: '条',
  选择分析信息: '选择分析信息',
  切换指标: '切换指标',
  确定: '确定',
  取消: '取消',
  重置: '重置',
  请输入关键字搜索: '请输入关键字搜索',
  搜索: '搜索',
  恢复: '恢复',
  趋势: '趋势',
  '已选 {} 项': '已选 {} 项',
  所选项: '所选项',
  组内升序: '组内升序',
  升序: '升序',
  降序: '降序',
  组内降序: '组内降序',
  不排序: '不排序',
  选择下钻维度: '选择下钻维度',
  恢复默认: '恢复默认',
  搜索字段: '搜索字段',

  // switcher
  行列切换: '行列切换',
  行头: '行头',
  列头: '列头',
  值: '值',
  展开子项: '展开子项',
  首字母: '首字母',
  手动排序: '手动排序',
  其他字段: '其他字段',
  高级排序: '高级排序',
  设置顺序: '设置顺序',
  可选字段: '可选字段',
  '按以下规则进行排序（优先级由低到高）':
    '按以下规则进行排序（优先级由低到高）',
  按: '按',
  说明: '说明',
};
