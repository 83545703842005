/**
 * Datart
 *
 * Copyright 2021
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import { Button, Col, Input, Row, Space, Table } from 'antd';
import { FormGroupLayoutMode } from 'app/components/FormGenerator/constants';
import GroupLayout from 'app/components/FormGenerator/Layout/GroupLayout';
import { ChartDataSectionType } from 'app/constants';
import { ChartConfigReducerActionType } from 'app/pages/ChartWorkbenchPage/slice/constant';
import { chartConfigSelector } from 'app/pages/ChartWorkbenchPage/slice/selectors';
import { updateChartConfigAndRefreshDatasetAction } from 'app/pages/ChartWorkbenchPage/slice/thunks';
import { ChartDataConfig, ChartStyleConfig } from 'app/types/ChartConfig';
import { IChartDrillOption } from 'app/types/ChartDrillOption';
import {
  getColumnRenderName,
  getUnusedHeaderRows,
} from 'app/utils/chartHelper';
import { getChartDrillOption } from 'app/utils/internalChartHelper';
import { DATARTSEPERATOR } from 'globalConstants';
import { FC, memo, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { CloneValueDeep } from 'utils/object';
import { TableColumnsList } from '../../ChartGraph/BasicTableChart/types';
import { ItemLayoutProps } from '../types';
import { itemLayoutComparer } from '../utils';
import './table.css';

const { Search } = Input;

const getFlattenHeaders = (myDataConfigs: ChartDataConfig[] = []) => {
  const newDataConfigs = CloneValueDeep(myDataConfigs);
  return newDataConfigs
    .filter(
      c =>
        ChartDataSectionType.AGGREGATE === c.type ||
        ChartDataSectionType.GROUP === c.type ||
        ChartDataSectionType.MIXED === c.type,
    )
    .flatMap(config => config.rows || []);
};

const setStyle = (myData, style, dataConfig) => {
  console.log("调用setStyle")
  if (myData) {
    myData.forEach(element => {
      if (!element.dataConfig && dataConfig) {
        dataConfig[0].rows = [CloneValueDeep(element)];
        element.dataConfig = dataConfig;
      }
      if (element.dataConfig && element.dataConfig.length > 0) {
        const index = element.dataConfig.findIndex(item => item.key === 'mixed')
        if (index > -1 && element.dataConfig[index].allTableHeaders) {
          element.dataConfig[index].allTableHeaders = null;
        }
      }
      if (style && !element.styleSetting) {
        let myStyle = style[3];
        myStyle.key = element.uid;
        element.styleSetting = CloneValueDeep(myStyle);
        console.log("element.styleSetting", element.styleSetting);
        
      } else {
        if (!element.isGroup && element.label) {
          delete element.label
          element.styleSetting.key = element.uid;
        }
      }
      if (element.children && element.children.length > 0) {
        setStyle(element.children, style, dataConfig);
      }
    });
    // setMyData(myData);
  }
};

const computeDatasource = (dataSoure, config, uid) => {
  if (dataSoure) {
    for (var j in dataSoure) {
      if (
        dataSoure[j].uid === uid &&
        dataSoure[j].styleSetting &&
        dataSoure[j].styleSetting.rows &&
        dataSoure[j].styleSetting.rows.length > 0
      ) {
        for (var i in dataSoure[j].styleSetting.rows) {
          // 判断修改的样式是哪个重新赋值
          if (config.key === dataSoure[j].styleSetting.rows[i].key) {
            dataSoure[j].styleSetting.rows[i] = CloneValueDeep(config);
            break;
          }
        }
      } else if (dataSoure[j].children && dataSoure[j].children.length > 0) {
        computeDatasource(dataSoure[j].children, config, uid);
      }
    }
  }
};
const UnControlledTableHeaderPanel: FC<ItemLayoutProps<ChartStyleConfig>> =
  memo(
    ({
      ancestors,
      translate: t = title => title,
      data,
      onChange,
      dataConfigs,
      onRef,
    }) => {
      const dispatch = useDispatch();
      const drillOptionRef = useRef<IChartDrillOption>();
      const chartConfig = useSelector(chartConfigSelector);
      const [selectedRowUids, setSelectedRowUids] = useState<string[]>([]);
      const [myData, setMyData] = useState(() => CloneValueDeep(data));
      const [myDataConfigs, setMyDataConfigs] = useState(() =>
        CloneValueDeep(dataConfigs),
      );

      console.log("调用UnControlledTableHeaderPanel")

      useImperativeHandle(onRef, () => {
        return {
          handleOKbefore: handleOKbefore,
        };
      });

      if (myDataConfigs) {
        if (myDataConfigs[0].rows && myDataConfigs[0].rows.length > 0) {
          let style = CloneValueDeep(chartConfig?.styles);
          setStyle(myDataConfigs[0].rows, style, CloneValueDeep(dataConfigs));
          if (myDataConfigs[0].rows && myDataConfigs[0].rows.length > 0) {
            // 获取所有的实际字段
            myDataConfigs[0].allTableHeaders = CloneValueDeep(
              myDataConfigs[0].rows,
            );
          }
          const getAllTableHeaders = data => {
            let tempList = [] as any[];
            data.forEach(element => {
              // 分组表头直接添加
              if (element.isGroup) {
                element.children = CloneValueDeep(
                  getAllTableHeaders(element.children),
                );
                if (myDataConfigs && myDataConfigs[0].allTableHeaders) {
                  myDataConfigs[0].allTableHeaders.push(element);
                }
                tempList.push(CloneValueDeep(element));
                // tempList.push.apply(
                //   tempList,
                //   CloneValueDeep(getAllTableHeaders(element.children)),
                // );
              } else {
                // 判断缓存的非分组表头是否已删除
                if (
                  myDataConfigs[0].allTableHeaders &&
                  myDataConfigs[0].allTableHeaders.length > 0
                ) {
                  let forFlag = true;
                  myDataConfigs[0].allTableHeaders.forEach(ele => {
                    // 由于同一个字段删除后在新增uid会变动所以使用字段名区别
                    if (forFlag && ele.colName === element.colName) {
                      tempList.push(CloneValueDeep(element));
                    }
                  });
                }
              }
            });
            return tempList;
          };
          setStyle(myData.value, style, CloneValueDeep(dataConfigs));
          if (myData.value && myData.value.length > 0) {
            myData.value = getAllTableHeaders(CloneValueDeep(myData.value));
          }
        } else {
          myDataConfigs[0].allTableHeaders = [];
        }
      } else {
        myData.value = [];
      }

      // 关闭对话框前调用该方法，进行重新渲染样式
      const handleOKbefore = () => {
        console.log('start');
        let configs = CloneValueDeep(myDataConfigs);
        console.log('configs-length: ', configs)
        let payload = {
          ancestors: [0],
          value: configs ? configs[0] : undefined,
          needRefresh: undefined,
        };
        let type = ChartConfigReducerActionType.DATA;
        const arg = {
          type,
          payload,
          needRefresh: payload.needRefresh,
          updateDrillOption: config => {
            drillOptionRef.current = getChartDrillOption(
              config?.datas,
              drillOptionRef.current,
            );
            return drillOptionRef.current;
          },
        };
        dispatch(updateChartConfigAndRefreshDatasetAction(arg));
        console.log('end');
      };

      const [tableDataSource, setTableDataSource] = useState<
        TableColumnsList[]
      >(() => {
        
        const originalFlattenHeaderRows = getFlattenHeaders(myDataConfigs);
        const currentHeaderRows: TableColumnsList[] = myData?.value || [];
        const unusedHeaderRows = getUnusedHeaderRows(
          originalFlattenHeaderRows || [],
          currentHeaderRows,
        );
        return currentHeaderRows.concat(unusedHeaderRows);
      });
      const handleStyleChange = (ancestors, config, needRefresh) => {
        let configs = CloneValueDeep(myDataConfigs);
        let dataSource = CloneValueDeep(tableDataSource);
        if (config && configs && configs[0].rows) {
          // 获取当前修改的表头
          configs[0].rows.forEach(element => {
            if (
              ancestors[0] === element.uid &&
              element.styleSetting &&
              element.styleSetting.rows
            ) {
              for (var i in element.styleSetting.rows) {
                // 判断修改的样式是哪个重新赋值
                if (config.key === element.styleSetting.rows[i].key) {
                  element.styleSetting.rows[i] = CloneValueDeep(config);
                  break;
                }
              }
            }
          });
          configs[0].allTableHeaders = Object.assign(
            configs[0].allTableHeaders,
            configs[0].rows,
          );
        }
        setMyDataConfigs(configs);
        computeDatasource(dataSource, config, ancestors[0]);

        // dispatch(workbenchSlice.actions.updateChartConfig(arg));
        handleConfigChange([...dataSource]);
      };
      const handleConfigChange = (dataSource: TableColumnsList[]) => {
        let style = CloneValueDeep(chartConfig?.styles);
        setStyle(dataSource, style, CloneValueDeep(dataConfigs));
        // setMyDataConfigs(myDataConfigs);
        myData.value = dataSource;
        setTableDataSource(dataSource);
        setMyData(myData);
        onChange?.(ancestors, myData);
      };
      // handleStyleChange(undefined, undefined, undefined);
      const mergeRowToGroup = () => {
        if (selectedRowUids.length === 0) {
          return;
        }
        const lineageRowUids = selectedRowUids.map(uid =>
          getAncestorRowUids(undefined, uid, tableDataSource),
        );
        const noDuplicateLineageRows =
          mergeSameLineageAncesterRows(lineageRowUids);
        const ancestorsRows = makeSameLinageRows(noDuplicateLineageRows);
        const newDataSource = groupTreeNode(ancestorsRows, tableDataSource);
        setSelectedRowUids([]);
        handleConfigChange([...newDataSource]);
      };

      const mergeSameLineageAncesterRows = lineageRowUids => {
        const allRowKeys = lineageRowUids.map((lr: string[]) =>
          lr.join(DATARTSEPERATOR),
        );
        return lineageRowUids.reduce((acc, next) => {
          const key = next.join(DATARTSEPERATOR);
          if (
            allRowKeys.some(k => k.includes(key) && k.length !== key.length)
          ) {
            return acc;
          }
          return acc.concat([next]);
        }, []);
      };

      const makeSameLinageRows = rowAncestors => {
        if (rowAncestors && rowAncestors.length === 0) {
          return [];
        }
        const theSortestLength = Math.min(...rowAncestors.map(ra => ra.length));
        let ancestorGeneration = 0;
        for (let i = 0; i < theSortestLength; i++) {
          const ancestor = rowAncestors[0][i];
          if (rowAncestors.every(a => a[i] === ancestor)) {
            ancestorGeneration = i;
          } else {
            break;
          }
        }
        return rowAncestors
          .map(ra => ra.slice(0, ancestorGeneration + 1))
          .reduce((acc, next) => {
            const key = next.join(DATARTSEPERATOR);
            const allRowKeys = acc.map(lr => lr.join(DATARTSEPERATOR));
            if (allRowKeys.includes(key)) {
              return acc;
            }
            return acc.concat([next]);
          }, []);
      };

      const getAncestorRowUids = (parentUid, rowUid, treeRows) => {
        if (treeRows.find(tr => tr.uid === rowUid)) {
          return !!parentUid ? [parentUid, rowUid] : [rowUid];
        }
        return treeRows.reduce((acc, next) => {
          return acc.concat(
            getAncestorRowUids(next.uid, rowUid, next.children || []),
          );
        }, []);
      };

      const groupTreeNode = (rowAncestors, collection) => {
        if (rowAncestors && rowAncestors.length < 1) {
          return collection;
        }

        const rows = collection || [];
        const linageGeneration = rowAncestors[0].length - 1;
        if (linageGeneration === 0) {
          const mergedKeys = rowAncestors.flatMap(ra => ra);
          return mergeBrotherRows(mergedKeys, rows);
        } else {
          const ancestor = rowAncestors[0][0];
          const subRowAncestors = rowAncestors.map(ra => ra.slice(1));
          const childRow = rows.find(c => c.colName === ancestor);
          childRow.children = groupTreeNode(subRowAncestors, childRow.children);
          return rows;
        }
      };

      const mergeBrotherRows = (
        mergeKeys: string[],
        rows: TableColumnsList[],
      ) => {
        const selectedRows = rows.filter(r => mergeKeys.includes(r.uid!));
        const restRows = rows.filter(r => !mergeKeys.includes(r.uid!));
        const insertIndex = rows.findIndex(r => r.uid === mergeKeys[0]);
        const groupRowUid = selectedRows.map(d => d.uid).join(DATARTSEPERATOR);

        // 判断是否是单个字段合并，如果是，需要在uid前面拼上'MERGED-'，以免父子uid相同
        const groupRowUidRep = selectedRows.length < 2 ?
          groupRowUid.replace(groupRowUid, 'MERGED-' + groupRowUid) : null;
        const groupRow = {
          uid: groupRowUidRep ? groupRowUidRep : groupRowUid,
          colName: groupRowUid,
          label: t('table.header.newName'),
          isGroup: true,
          children: selectedRows,
        };
        if (!restRows.find(rr => rr.uid === groupRowUid)) {
          restRows.splice(insertIndex, 0, groupRow);
        }
        return restRows;
      };

      const handleRowMoveUp = () => {
        selectedRowUids.forEach(rowUid => {
          const brotherRows = findRowBrothers(rowUid, tableDataSource);
          const idx = brotherRows.findIndex(s => s.uid === rowUid);
          if (idx < 1) {
            return;
          }
          const temp = brotherRows[idx - 1];
          brotherRows[idx - 1] = brotherRows[idx];
          brotherRows[idx] = temp;
        });
        handleConfigChange([...tableDataSource]);
      };

      const handleRowMoveDown = () => {
        selectedRowUids.forEach(uid => {
          const brotherRows = findRowBrothers(uid, tableDataSource);
          const idx = brotherRows.findIndex(s => s.uid === uid);
          if (idx >= brotherRows.length - 1) {
            return;
          }
          const temp = brotherRows[idx];
          brotherRows[idx] = brotherRows[idx + 1];
          brotherRows[idx + 1] = temp;
          handleConfigChange([...tableDataSource]);
        });
      };

      const handleRollback = () => {
        const originalFlattenHeaders = getFlattenHeaders(myDataConfigs);
        myData.value = [];
        setTableDataSource(originalFlattenHeaders);
        setMyData(myData);
        onChange?.(ancestors, myData);
      };

      const handleTableRowChange = rowUid => style => prop => (_, value) => {
        const brotherRows = findRowBrothers(rowUid, tableDataSource);
        const row = brotherRows.find(r => r.uid === rowUid);

        if (!row) {
          return;
        }
        if (style) {
          row.style = Object.assign({}, row.style, {
            ...row.style,
            [prop]: value,
          });
        } else {
          row[prop] = value;
        }
        handleConfigChange([...tableDataSource]);
      };

      const handleDeleteGroupRow = rowUid => {
        const brotherRows = findRowBrothers(rowUid, tableDataSource);
        const idx = brotherRows.findIndex(s => s.uid === rowUid);
        brotherRows.splice(idx, 1, ...(brotherRows[idx].children || []));
        handleConfigChange([...tableDataSource]);
      };

      const findRowBrothers = (uid, rows) => {
        let row = rows.find(r => r.uid === uid);
        if (!!row) {
          return rows;
        }
        let subRows = [];
        for (let i = 0; i < rows.length; i++) {
          subRows = findRowBrothers(uid, rows[i].children || []);
          if (!!subRows && subRows.length > 0) {
            break;
          }
        }
        return subRows;
      };
      const tableColumnsSettings = [
        {
          title: t('table.header.columnName'),
          dataIndex: 'colName',
          key: 'colName',
          render: (_, record) => {
            const { label, isGroup, uid } = record;
            return isGroup ? (
              <>
                <DeleteOutlined
                  style={{ marginRight: 10 }}
                  onClick={_ => handleDeleteGroupRow(uid)}
                />
                <EditableLabel
                  label={label}
                  onChange={value =>
                    handleTableRowChange(uid)(undefined)('label')([], value)
                  }
                />
              </>
            ) : (
              getColumnRenderName(record)
            );
          },
        },
        {
          title: t('table.header.columnStyle'),
          dataIndex: 'uid',
          width: 600,
          key: 'uid',
          render: (_, record) => {
            return (
              <GroupLayout
                ancestors={[record.uid]}
                mode={
                  record.styleSetting.comType === 'group'
                    ? FormGroupLayoutMode.INNER
                    : FormGroupLayoutMode.OUTER
                }
                data={record.styleSetting}
                dataConfigs={record.dataConfig}
                translate={t}
                onChange={handleStyleChange}
                handleOKbefore={handleOKbefore}
              />
            );
          },
        },
      ];

      const rowSelection = {
        selectedRowKeys: selectedRowUids,
        onChange: (selectedRowKeys: any[]) => {
          setSelectedRowUids(selectedRowKeys);
        },
      };
      return (
        <StyledUnControlledTableHeaderPanel direction="vertical">
          <Row gutter={24}>
            <Col span={20}>
              <Space>
                <Button
                  disabled={selectedRowUids.length === 0}
                  type="primary"
                  onClick={mergeRowToGroup}
                >
                  {t('table.header.merge')}
                </Button>
                <Button
                  disabled={selectedRowUids.length === 0}
                  icon={<ArrowUpOutlined />}
                  onClick={handleRowMoveUp}
                >
                  {t('table.header.moveUp')}
                </Button>
                <Button
                  disabled={selectedRowUids.length === 0}
                  icon={<ArrowDownOutlined />}
                  onClick={handleRowMoveDown}
                >
                  {t('table.header.moveDown')}
                </Button>
              </Space>
            </Col>
            <Col span={4}>
              <Row justify="end" align="middle">
                <Button icon={<RedoOutlined />} onClick={handleRollback}>
                  {t('table.header.reset')}
                </Button>
              </Row>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Table
                size="small"
                bordered={true}
                pagination={false}
                {...myData}
                rowKey={record => record.uid!}
                columns={tableColumnsSettings}
                dataSource={tableDataSource}
                rowSelection={rowSelection}
              />
            </Col>
          </Row>
        </StyledUnControlledTableHeaderPanel>
      );
    },
    itemLayoutComparer,
  );

const EditableLabel: FC<{
  label: string;
  editable?: Boolean;
  onChange: (value: string) => void;
}> = memo(({ label, editable = true, onChange }) => {
  const [isEditing, setIsEditing] = useState(false);

  const render = () => {
    if (!editable) {
      return <span>{label}</span>;
    }
    return isEditing ? (
      <Search
        enterButton={<CheckOutlined />}
        placeholder={label}
        size="small"
        onSearch={value => {
          if (!!value) {
            setIsEditing(false);
            onChange(value);
          }
        }}
      />
    ) : (
      <>
        <span>{label}</span>
        <Button
          type="text"
          size="small"
          icon={<EditOutlined />}
          onClick={() => setIsEditing(true)}
        ></Button>
      </>
    );
  };

  return <StyledEditableLabel>{render()}</StyledEditableLabel>;
});

const StyledEditableLabel = styled.div`
  display: inline-block;
`;

const StyledUnControlledTableHeaderPanel = styled(Space)`
  width: 100%;
  margin-top: 10px;
`;

export default UnControlledTableHeaderPanel;
