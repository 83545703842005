import {request, request2} from "../../utils/request";
import {errorHandle} from "../../utils/utils";
import produce from "immer";
import {message} from "antd";

const getParentNode = (node: HTMLElement): HTMLElement | null => {
  if(node && node?.getAttribute('data-event')) return node;
  else if(node.parentElement) return getParentNode(node.parentElement);
  else return null;
};

window.addEventListener('popstate', function(event) {
  console.log("wwwww", event);
});


const INTERVAL = 100;
let lastEventDate = new Date().getTime();
document.body.addEventListener('click', (e) => {
  const now = new Date().getTime();
  if(now - lastEventDate < INTERVAL) return;
  lastEventDate = now;

  const node = e.target as HTMLDivElement;
  const eventNode = node?.parentElement?.querySelector("*[data-event]") || getParentNode(node)
  if(eventNode) {
    const event = eventNode.getAttribute('data-event') ?? "";
    const relType = eventNode.getAttribute('data-relType') ?? "";
    const relId = eventNode.getAttribute('data-relId') ?? "";
    const title = eventNode.getAttribute('data-title') ?? "";
    const extra: {} | string | undefined = eventNode.getAttribute('data-extra') ?? undefined;
    console.log(event)
    if (event) {
      let extraStr: string = extra ? typeof extra == 'string' ? extra : JSON.stringify(extra) : "";
      addTrackingPoint({
        datas: [{
          path: window.location.href,
          event,
          relType,
          relId,
          title,
          extra: extraStr
        }]});
    }
  }
}, true)

const addTrackingPoint = (data: trackingPointCreateParam) => {
  console.log(data)
  try {
    request2({
      url: `/tracking`,
      method: 'POST',
      data: data
    });
  } catch (err) {
    console.log("埋点出错：" + err);
  }
}

export interface trackingPointCreateParam {
  datas: trackingPoint[];
}

export interface trackingPoint {
  userName?: string;
  nickName?: string;
  relType?: string;
  relId?: string;
  event?: string;
  title?: string;
  extra?: string;
  path?: string;
}


export default {}